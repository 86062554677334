<template>
  <el-card class="carpeting" v-loading="loading">
    <div class="content">
      <div class="flex flex-align-center flex-justify-center" style="gap:20px">
        <div class="flex flex-align-center" style="gap:10px">
          <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/shopvideo-image.png" alt="">
          <span class="font-16 color-909399">视频号小店</span>
        </div>
        <div class="font-18 color-909399">
          <i class = "el-icon-close" style="font-size:30px"></i>
        </div>
        <div class="flex flex-align-center" style="gap:10px">
          <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/shopvideo-qidian-logo.png" alt="">
          <span class="font-16 color-909399">企店</span>
        </div>
      </div>
      <div class="margin-top-40 font-14 color-909399 line-font-24" style="width:400px">
        绑定视频号小店，视频号场景卖货订单同步回企店商城，实现视频号内分销裂变，助力小店创造更多营收
        <el-link href="https://jusnn6k8al.feishu.cn/docx/J2ttdI8tCoap1ZxAsUOcVZ0ZnUb"
        :underline="false" target="_blank" class="margin-left-10" style="color:#409eff;margin-top:-2px">查看绑定教程</el-link>
      </div>
      <div style="margin-top:15px" class="flex flex-justify-center">
        <el-button type="primary" @click="toBind">立即绑定</el-button>
      </div>
    </div>
  </el-card>
</template>

<script>
import {
  channelShopchannelShopBindValite
} from "@/api/goods"
export default {
  props: {
    type:{
      type:Number,
      default:0
    }
  },
  data () {
    return {
      loading:false,
      IsBind:false,
    }
  },
  mounted () {
    this.$emit('begin')
    this.getInfo()
  },
  methods: {
    async getInfo(){
      try{
        this.loading = true
        let res = await channelShopchannelShopBindValite({
          Type:this.type
        })
        if(res.IsSuccess){
          this.IsBind = res.Result.IsBind
          // console.log(this.IsBind)
          this.$emit('complete',this.IsBind)
        }
      }finally{
        this.loading = false
      }
    },
    toBind(){
      this.$router.push({
        path:'/videoShop/videoShopBinding'
      })
    }
  }
}
</script>

<style lang='less' scoped>
.carpeting{
  z-index: 1000;
  position: absolute;
  left: 10px;
  right: 10px;
  top: 10px;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  .content{
    img{
      width: 30px;
    }
  }
}
</style>